/* COLORS */
div.c1, button.c1, nav.c1, .btn.c1, span.c1{
  background-color: #782d7d !important;
  color: white;
}
.btn.c1:hover{
  background-color: #0061AA !important;
}
div.c2, button.c2, nav.c2, .btn.c2, span.c2{
  background-color: #8F44DC !important;
  color: white;
}
div.b2{
  border-color: #8F44DC !important;
}
.btn.c2:hover{
  background-color: #7228bf !important;
}
div.c3, button.c3, nav.c3, .btn.c3, span.c3{
  background-color: #340638  !important;
  color: white;
}
.btn.c3:hover{
  background-color: #261D54 !important;
}

.page-title.c2{
  background: #183153 !important;
}
.page-title .btn-primary.c3{
  background: transparent!important;
  border: 2px solid white !important;
  border-radius: 50px;
}
.page-title .btn-primary.c3:hover{
  background: #4F6C7C !important;
  border-color: #4F6C7C !important;
  color: white !important;
}

.outline.btn.btn-primary.c3{
  background: transparent!important;
  border: 2px solid white !important;
  border-radius: 50px;
}

.outline.btn.btn-primary.c3:hover{
  background: #95aac9  !important;
  border-color: #95aac9  !important;
  color: white !important;
}
button.btn-outline-primary{
  background-color: transparent !important;
  border: 1px solid;
  color: #782d7d;
  border-color: #782d7d;
}
button.btn-outline-primary:hover {
  color: #fff;
  background-color: #782d7d !important;
  border-color: #782d7d !important;
}

/* GENERAL */
#sidebar .nav-active {
  background: transparent linear-gradient(270deg, #a53ebf 0%, #782d7d 100%) 0% 0% no-repeat padding-box;
  border-left: solid 2px #a53ebf;
}
.sidebarIcon {
  color: #a43ebd;
}
.card-one {
  background: #782d7d 0% 0% no-repeat padding-box;
}
.card-two {
  background: #580d5d 0% 0% no-repeat padding-box;
}
.card-three {
  background: #49034e 0% 0% no-repeat padding-box;
}
.card-four {
  background: #3d0042 0% 0% no-repeat padding-box;
}
/* LOGIN */
.bg-cover{
  background: linear-gradient(#782d7d, #580d5d) 0% 0% / cover;
}
#form-login button {
  background:#782d7d;
  border: 0;
  transition: background 0.5s;
}
#form-login button:hover {
  background: rgb(81 8 86);
}
/*LOADING*/
#loading-border{
  border-color: #782d7d;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
    color: #580d5d;
  }
  50% {
    transform: rotate(180deg);
    color: #782d7d;
  }
  100% {
      transform: rotate(359deg);
      color: #580d5d;
  }
}
#loading-icon span{
  background-color: #782d7d;
}
#loading p:nth-child(2){
  color: #782d7d;
}

/* EVENT CREATE */
#event-accept {
  background: #782D7D;
}
.step-active {
  border-left-color: #782D7D;
  color: #782D7D;
}
.step-active .step-bullet {
  color: #782D7D;
  border-color: #782D7D;
}
.event-tags .event-tag {
  background-color: #782d7d;
}
#create-event-2 .btn-blue-border {
  color: #782d7d;
  border: 2px solid #782d7d !important;
}
#event-accept .btn-white {
  color: #782d7d;
}
.alert-primary{
  background-color: #782d7d !important;
  border-color: #782d7d !important;
}